import React from 'react';
import ReactDOM from 'react-dom/client';
import "../MenuComponent.css"
import "../Mobile.css";

import fio_banner from "../images/FIO-Banner.jpg";
import fio_rack from "../images/FIO-Rack.jpg";
import fio_driver from "../images/FIO-Driver.jpg";

class ImpressumComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }



    render() {
        return (



            <div >
                <h1>Impressum</h1>

                <div className='content-detail' >

                    <p>Für Ihre Ohren - Subwoofer Verein</p>
                    <p>ZVR-Zahl - 1752140118</p>

                    <p>Niesenbergergasse 77 - 8020 Graz</p>

                 
                    <p style={{marginTop:"70px", marginBottom:"30px", borderTop:"solid 1px", paddingTop:"30px"}} className='content-h'>Fotos</p> 
                    
                    <p> &#169; FIO-SV und <br/> &#169; Alexander Erlacher</p>

                </div>


                
            </div>
        );
    }
}

export default ImpressumComponent;