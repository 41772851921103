import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import "../MenuComponent.css"
import "../Mobile.css";

import fio_banner from "../images/FIO-Banner.jpg";
import fio_rack from "../images/FIO-Rack.jpg";
import fio_driver from "../images/FIO-Driver.jpg";
import fio_ddj from "../images/FIO-Ddj.jpg";
import fio_jaga from "../images/FIO-Jaga.jpg";
import fio_robert from "../images/FIO-Robert.jpg";

//import SoundSystemComponent from './SoundSystemComponent';
//import AboutUsComponent from './AboutUsComponent';
//import InstagramComponent from './InstagramComponent';
import MenuComponent from './MenuComponentAlt';
import EventComponent from './EventComponent';

class EventToogleComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            component: <EventComponent/>,
            homepage: false,
            buttText: "FIO Homepage"
        };
    }

    handleToggle = () => {
        if (this.state.homepage) {
            this.setState({ component: <EventComponent/> });
            this.setState({ buttText: "FIO Homepage"});
            this.setState({ homepage: false});
        }
        else {
            this.setState({ component: <MenuComponent/> });
            this.setState({ buttText: "FIO Bass Picknick"});
            this.setState({ homepage: true});
        }
        
    }


    render() {
        return (

           <div>
           <button className="toggleBtn" onClick={() => { this.handleToggle() }} > {this.state.buttText} </button>
           {this.state.component}
           </div>
      
                       
        );
    }
}

export default EventToogleComponent;