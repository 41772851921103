import React from 'react';
import ReactDOM from 'react-dom/client';
import "../MenuComponent.css"
import "../Mobile.css";
import ImpressumComponent from './ImpressumComponent';

import fio_banner from "../images/FIO-Banner.jpg";
import fio_rack from "../images/FIO-Rack.jpg";
import fio_driver from "../images/FIO-Driver.jpg";

class AboutUsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            impress : "none"

        };
    }

    toggleImpress = () => {
        if(this.state.impress == "none")
            this.setState({impress: "block"});
        else if (this.state.impress ==  "block")
            this.setState({impress: "none"});

    }


    render() {

       
        return (

            <div >
                <h1>About Us</h1>

                <div className='content-detail' >

                    <h2>Not your ordinary collective</h2>

                    <p>Gemeinnütziger Verein mit Schwerpunkt auf hochqualitative Soundsysteme und ausschweifende Raves mit ordentlich Druck. </p>

                </div>
                <h1>Contact Information </h1>

                
                <div className='content-detail' >

                    <p className='content-h'>Instagram </p><p> <a href='https://www.instagram.com/therealfiosv/?theme=dark' target="_blank"> therealfiosv</a> 
                 </p> <p className='content-h'> E-mail</p> <p> <a href='mailto:info@fio-sv.at' > info@fio-sv.at</a></p>

                    <table className='nav-bar' style={{borderWidth: "0px"}}>
                    <tbody><tr><td style={{width: "220px", marginRight: "auto", marginLeft:"auto"}}
                                onClick={() => {this.toggleImpress()}}
                            >Impressum</td>
                        </tr></tbody></table>

                <div style={{display: this.state.impress}}><ImpressumComponent /></div>
                </div>
            </div>
        );
    }
}

export default AboutUsComponent;