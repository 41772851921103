import React from 'react';
import ReactDOM from 'react-dom/client';
import "../MenuComponent.css"
import "../Mobile.css";

import fio_banner from "../images/FIO-Banner.jpg";
import fio_rack from "../images/FIO-Rack.jpg";
import fio_driver from "../images/FIO-Driver.jpg";

class HomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

   

    render() {
        return (



            <div style={{marginTop:"70px"}}>
         
               <div className='zitat' >
               <h2>"Die schönen Tage sind das Privileg der Reichen, <br/> aber die harten Raves mit ordentlich Druck <br/>sind das Monopol der Glücklichen."</h2>
               <p className='zitat-source'> - Nes(des)troy</p>

               



    

                  

                </div>
            </div>
        );
    }
}

export default HomeComponent;