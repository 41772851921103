import React from 'react';
import ReactDOM from 'react-dom/client';
import "../MenuComponent.css"
import "../Mobile.css";

import fio_banner from "../images/FIO-Banner.jpg";
import fio_rack from "../images/FIO-Rack.jpg";
import fio_driver from "../images/FIO-Driver.jpg";
import fio_ddj from "../images/FIO-Ddj.jpg";
import fio_jaga from "../images/FIO-Jaga.jpg";
import fio_robert from "../images/FIO-Robert.jpg";

class InstagramComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }



    render() {
        return (



            <div >
                <h1>Instagram</h1>

                <iframe className='content-instagram' src="https://www.instagram.com/therealfiosv/embed/?theme=dark"  scrolling="no"></iframe>
            </div>
        );
    }
}

export default InstagramComponent;